<template>
  <div class="about">
    <div class="header-aboutus about-us-section">
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-12 col-md-6 p-4">
          <h1 class="text-center">
            <span style="color: #F50201">{{
              stringAsset("aboutus/application-name", lang)
            }}</span>
          </h1>
          <h3 class="text-center">
            {{ stringAsset("aboutus/main-heading", lang) }}
          </h3>
          <h5 class="text-center">
            <span style="color: #A7ABBE">{{
              stringAsset("aboutus/sub-heading", lang)[0]
            }}</span>
            <span style="color: #F50201">{{
              stringAsset("aboutus/sub-heading", lang)[1]
            }}</span>
            <span style="color: #A7ABBE">{{
              stringAsset("aboutus/sub-heading", lang)[2]
            }}</span>
          </h5>
        </div>
      </div>
    </div>

    <div class="my-platform about-us-section">
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-12 col-md-6 p-4">
          <h1 class="text-center">
            <span style="color: #F50201">{{
              stringAsset("aboutus/feature-heading-1", lang)
            }}</span>
          </h1>
        </div>
      </div>
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-12 col-md-4 p-5 text-center mt-auto mb-auto">
          <img
            class="pb-4"
            src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/matchdayapplication.jpg"
            style="width: 150px"
          />
          <h4>{{ stringAsset("aboutus/feature-item-1", lang) }}</h4>
          <h6>
            <span style="color: #A7ABBE">{{
              stringAsset("aboutus/sub-feature-item-1", lang)
            }}</span>
          </h6>
        </div>
        <div class="col-sm-12 col-md-4 p-5 text-center mt-auto mb-auto">
          <img
            class="pb-4"
            src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/arenapos.jpg"
            style="width: 150px"
          />
          <h4>{{ stringAsset("aboutus/feature-item-2", lang) }}</h4>
          <h6>
            <span style="color: #A7ABBE">{{
              stringAsset("aboutus/sub-feature-item-2", lang)
            }}</span>
          </h6>
        </div>
      </div>
    </div>

    <div class="my-partner about-us-section">
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-12 col-md-6 p-4">
          <h1 class="text-center">
            <span style="color: #F50201">{{
              stringAsset("aboutus/feature-heading-2", lang)
            }}</span>
          </h1>
        </div>
      </div>
      <div class="row no-gutters justify-content-center">
        <div
          v-for="(item, index) in stringAsset('aboutus/collaborators', lang)"
          :key="index"
          class="col-sm-12 col-md-2 p-3 text-center mt-auto mb-auto"
        >
          <img :src="item" style="width: 150px" />
        </div>
      </div>
    </div>

    <div class="footer-aboutus about-us-section">
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-12 col-md-6 p-4">
          <div class="bg-primary" style="height:2px;"></div>
          <h5 class="text-center p-4">
            <span
              >{{ stringAsset("aboutus/footer-content", lang)[0] }} <br />
              {{ stringAsset("aboutus/footer-content", lang)[1] }}</span
            >
            <span style="color: #F50201">{{
              stringAsset("aboutus/email-contact", lang)
            }}</span>
          </h5>
          <div class="bg-primary" style="height:2px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStringAsset } from "../assets/strings";

export default {
  data() {
    return {
      lang: "th"
    };
  },
  methods: {
    stringAsset: getStringAsset
  }
};
</script>

<style>
.bg-about {
  background-image: url("https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/BG_Logo_gjbbbx.png");
  background-repeat: no-repeat;
  background-size: 400px 400px contain;
  background-position: 50% 50%;
}
.about-us-section {
  padding: 2rem 0;
  margin: 2rem 0;
}
</style>
